<template>
  <div class="allmore">
    <div v-show="open!=true">
      <span class="iconclass">
        <van-icon name="arrow-down" />
      </span>
      <span>加载更多</span>
    </div>
    <div v-show="open==true">
      <van-loading type="spinner" color="#1989fa" />
    </div>
  </div>
</template>
<script>
export default {
  props: {
      open:Boolean
  },
};
</script>
<style scoped>
.allmore {
  width: 80%;
  background-color: #ffffff;
  margin: 0.3rem 0 0.1rem 50%;
  transform: translate(-50%);
  text-align: center;
  position: relative;
  padding: 0.3rem 0.2rem;
  font-size: 0.35rem;
  border-radius: 2rem;
  color: #202124;
}
.iconclass {
  position: absolute;
  left: 0.45rem;
  top: 50%;
  transform: translateY(-50%);
}
</style>